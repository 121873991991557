import { createTheme } from '@mui/material/styles';
const primaryColor = window['webAppConfig'].colors.primary;
const defaultGrey = "#BCBCBC";

const PaypsMuiTheme = createTheme({
    palette: {
      primary: {
        main: window['webAppConfig'].colors.secondary,
      },
      secondary: {
        main: window['webAppConfig'].colors.background,
      },
    },
    components:{
      MuiInputBase:{
        styleOverrides:{
          input: {
            //color: window['webAppConfig'].colors.primary,
            backgroundColor: 'transparent',
            color: '#474747',
        }
        },
      },
      switchBase: {
        color: defaultGrey,
        '&$checked': {
          color: primaryColor,
        },
        '&$checked + $track': {
          backgroundColor: primaryColor,
        },
        'label':{
            fontSize: "1.1em !important",
        }
      },
      root:{
          fontSize: "1.1em !important",
      },
      checked: {},
      track: {},
    },
  });

  export default PaypsMuiTheme;